const ifaceMesssages = {
  btn: {
    publishAnsw: '¿Publicar Post?',
    publish: '¡Si, Publicar Post!',
  },
};

export const articles = {
  dbDocName: 'posts',
  minCharLength: {
    default: 5,
    post: 15,
  },
  propID: 'id',
  ifaceMesssages,
};
